import { create } from "zustand";
import { persist, PersistOptions } from "zustand/middleware";

export interface ICanvaState {
  elements: CanvaElement[];
  asin: string;
  isWhatIf: boolean;
}

export type CanvaElement = {
  id: string;
  elementId: ELEMENT_ID;
  x: number;
  y: number;
  size?: {
    width: number;
    height: number;
  };
  active: boolean;
};

export enum ELEMENT_ID {
  PRODUCT_DETAILS = "PRODUCT_DETAILS",
  EXTERNAL_PRODUCT_DETAILS = "EXTERNAL_PRODUCT_DETAILS",
  SCORE_REVIEWS = "SCORE_REVIEWS",
  ASPECT_OPTIMIZATION = "ASPECT_OPTIMIZATION",
  CONTENT_OPTIMIZATION = "CONTENT_OPTIMIZATION",
  PRICE_OPTIMIZATION = "PRICE_OPTIMIZATION",
  REVIEWS_OPTIMIZATION = "REVIEWS_OPTIMIZATION",
  EXECUTIVE_SUMMARY = "EXECUTIVE_SUMMARY",
  ADS_SALES_SLIDERS = "ADS_SALES_SLIDERS",
  ADS_SALES = "ADS_SALES",
  ADS_SANKEY = "ADS_SANKEY",
  ORGANIC_SALES = "ORGANIC_SALES",
  FUNNEL = "FUNNEL",
  SUMMARY_AUDIT = "SUMMARY_AUDIT",
  CAMPAIGN_METRICS = "CAMPAIGN_METRICS",
  ORGANIC_KEYWORD_PERFORMANCE = "ORGANIC_KEYWORD_PERFORMANCE",
  ORGANIC_MISSING_TARGET = "ORGANIC_MISSING_TARGET",
}

interface CanvaStore extends ICanvaState {
  addElement: (element: CanvaElement) => void;
  removeElement: (id: string) => void;
  removeElementByName: (elementId: ELEMENT_ID) => void;
  updateElementPosition: (id: string, x: number, y: number) => void;
  clearCanvas: () => void;
  setAsin: (asin: string) => void;
  setIsWhatIf: (isWhatIf: boolean) => void;
}

type CanvaStorePersist = (
  config: (set: any, get: any) => CanvaStore,
  options: PersistOptions<CanvaStore>,
) => (set: any, get: any) => CanvaStore;

export const useCanvaStore = create<CanvaStore>((set) => ({
  elements: [],
  asin: "",
  isWhatIf: false,
  setIsWhatIf: (isWhatIf) => set({ isWhatIf }),
  setAsin: (asin) => set({ asin }),
  addElement: (element) =>
    set((state) => ({
      elements: [...state.elements, element],
    })),

  removeElement: (id) =>
    set((state) => ({
      elements: state.elements.filter((el) => el.id !== id),
    })),

  removeElementByName: (elementId) =>
    set((state) => ({
      elements: state.elements.filter((el) => el.elementId !== elementId),
    })),

  updateElementPosition: (id, x, y) =>
    set((state) => ({
      elements: state.elements.map((el) =>
        el.id === id ? { ...el, x, y } : el,
      ),
    })),

  clearCanvas: () =>
    set(() => ({
      elements: [],
    })),
}));
