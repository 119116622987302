import { ELEMENT_ID } from "src/store/canva.state";
import { Element } from "./ElementsAccordion";
import { v4 as uuid } from "uuid";
import browser from "@assets/icons/canvas/browser.png";
import chat from "@assets/icons/canvas/chat.png";
import chart from "@assets/icons/canvas/chart.png";
import procent from "@assets/icons/canvas/procent.png";
import settings from "@assets/icons/canvas/settings.png";
import table from "@assets/icons/canvas/table.png";
import list from "@assets/icons/canvas/list.png";
import sankey from "@assets/icons/canvas/sankey.png";
import columns from "@assets/icons/canvas/columns.png";
import funnel from "@assets/icons/canvas/funnel.png";
import lightbulb from "@assets/icons/canvas/lightbulb.svg";
import browserThin from "@assets/icons/canvas/browser-thin.png";

export const croElements: Element[] = [
  {
    id: uuid(),
    elementId: ELEMENT_ID.PRODUCT_DETAILS,
    name: "Product details",
    active: false,
    icon: browser,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.EXTERNAL_PRODUCT_DETAILS,
    name: "External product page",
    active: false,
    icon: browserThin,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.SCORE_REVIEWS,
    name: "Reviews Score",
    active: false,
    icon: chart,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ASPECT_OPTIMIZATION,
    name: "Aspect optimization",
    active: false,
    icon: procent,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.CONTENT_OPTIMIZATION,
    name: "Content optimization",
    active: false,
    icon: settings,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.PRICE_OPTIMIZATION,
    name: "Price optimization",
    active: false,
    icon: settings,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.REVIEWS_OPTIMIZATION,
    name: "Reviews optimization",
    active: false,
    icon: chat,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.EXECUTIVE_SUMMARY,
    name: "Executive summary",
    active: false,
    icon: table,
  },
];

export const campaignElements: Element[] = [
  {
    id: uuid(),
    elementId: ELEMENT_ID.CAMPAIGN_METRICS,
    name: "Campaign metrics",
    active: false,
    icon: table,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ADS_SALES_SLIDERS,
    name: "Ad Spend",
    active: false,
    icon: settings,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ADS_SALES,
    name: "Ads sales",
    active: false,
    icon: list,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ADS_SANKEY,
    name: "Ads sankey",
    active: false,
    icon: sankey,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.FUNNEL,
    name: "Ads Funnel",
    active: false,
    icon: funnel,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.SUMMARY_AUDIT,
    name: "Summary of audit",
    active: false,
    icon: lightbulb,
  },
];

export const organicElements: Element[] = [
  {
    id: uuid(),
    elementId: ELEMENT_ID.ORGANIC_SALES,
    name: "Organic Sales",
    active: false,
    icon: columns,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ORGANIC_KEYWORD_PERFORMANCE,
    name: "Organic Keyword Performance",
    active: false,
    icon: columns,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ORGANIC_MISSING_TARGET,
    name: "Recommended Missing Targets",
    active: false,
    icon: columns,
  },
];

export const getWidgetSize = (elementId: ELEMENT_ID) => {
  switch (elementId) {
    case ELEMENT_ID.ADS_SANKEY:
      return { width: 3, height: 100 };
    case ELEMENT_ID.ADS_SALES:
      return { width: 3, height: 76 };
    case ELEMENT_ID.ORGANIC_SALES:
    case ELEMENT_ID.ORGANIC_KEYWORD_PERFORMANCE:
    case ELEMENT_ID.ORGANIC_MISSING_TARGET:
    case ELEMENT_ID.CAMPAIGN_METRICS:
      return { width: 3, height: 60 };
    case ELEMENT_ID.SCORE_REVIEWS:
    case ELEMENT_ID.SUMMARY_AUDIT:
    case ELEMENT_ID.ADS_SALES_SLIDERS:
    case ELEMENT_ID.EXECUTIVE_SUMMARY:
      return { width: 2, height: 50 };
    case ELEMENT_ID.PRODUCT_DETAILS:
      return { width: 2, height: 120 };
    case ELEMENT_ID.FUNNEL:
      return { width: 2, height: 76 };
    case ELEMENT_ID.ASPECT_OPTIMIZATION:
    case ELEMENT_ID.CONTENT_OPTIMIZATION:
      return { width: 1, height: 92 };
    case ELEMENT_ID.REVIEWS_OPTIMIZATION:
      return { width: 1, height: 40 };
    case ELEMENT_ID.EXTERNAL_PRODUCT_DETAILS:
    case ELEMENT_ID.PRICE_OPTIMIZATION:
      return { width: 1, height: 67 };
    default:
      return { width: 1, height: 60 };
  }
};
