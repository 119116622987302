import { ProductText } from "@pages/CROModule/components/Product/ProductText";
import { ELEMENT_ID } from "src/store/canva.state";
import styles from "./styles.module.scss";
import { ReviewChart } from "@pages/Overview/components/ReviewScore/ReviewScore";
import { ListingOptimisation } from "@pages/CROModule/components/Product/Optimisation/components/DefaultOptimization/Listing";
import { PriceOptimisation } from "@pages/CROModule/components/Product/Optimisation/components/DefaultOptimization/Price";
import { AdSpend } from "@pages/AdsAnalitics/components/Campaigns/components/AdSpend";
import { CampaignsTable } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignTable";
import { SanKey } from "@pages/AdsAnalitics/components/SanKey";
import { ReviewsOptimization } from "../Elements/Collection/ReviewsOptimization";
import { OrganicSalesTable } from "@components/OrganicSales";
import { FunnelChart } from "@components/FunnelChart";
import { AspectOptimization } from "@pages/CROModule/components/Product/Optimisation/components/DefaultOptimization/Aspect";
import { SummaryAudit } from "@components/SummaryAudit";
import { CampaignsChart } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart";
import { OrganicKeywordTable } from "@components/OrganicKeyword";
import { OrganicMissingTargetTable } from "@components/OrganicMissingTarget";
import { WhatIfTable } from "@pages/AdsAnalitics/components/Campaigns/components/WhatIfTable";
import { ExecutiveSummary } from "../Elements/Collection/ExecutiveSummary";

export const renderElement = (
  elementId: ELEMENT_ID,
  asin: string,
  isWhatIf: boolean,
) => {
  switch (elementId) {
    case ELEMENT_ID.PRODUCT_DETAILS:
      return (
        <>
          <div className={styles.product}>
            <ProductText customAsin={asin} showColumn={"right"} />
          </div>
        </>
      );
    case ELEMENT_ID.EXTERNAL_PRODUCT_DETAILS:
      return (
        <>
          <div className={styles.product}>
            <ProductText customAsin={asin} showColumn={"left"} />
          </div>
        </>
      );
    case ELEMENT_ID.SCORE_REVIEWS:
      return (
        <div className={styles.products}>
          <h3>Reviews Score</h3>
          <ReviewChart />
        </div>
      );
    case ELEMENT_ID.ASPECT_OPTIMIZATION:
      return (
        <div className={styles.optimisation}>
          <AspectOptimization
            isWhatIf={isWhatIf}
            defaultOpen
            disableHide
            customAsin={asin}
          />
        </div>
      );
    case ELEMENT_ID.CONTENT_OPTIMIZATION:
      return (
        <div className={styles.optimisation}>
          <ListingOptimisation
            isWhatIf={isWhatIf}
            defaultOpen
            disableHide
            asin={asin}
          />
        </div>
      );
    case ELEMENT_ID.PRICE_OPTIMIZATION:
      return (
        <div className={styles.optimisation}>
          <PriceOptimisation defaultOpen disableHide />
        </div>
      );
    case ELEMENT_ID.ADS_SALES_SLIDERS:
      return (
        <div className={styles.sliders}>
          <AdSpend isSmall />
        </div>
      );
    case ELEMENT_ID.ADS_SALES:
      return (
        <div className={styles.products}>
          <h3>Ads Sales</h3>
          {isWhatIf ? <WhatIfTable hideAsin /> : <CampaignsTable hideAsin />}
        </div>
      );
    case ELEMENT_ID.ADS_SANKEY:
      return <SanKey canvaAsin={asin} hideAsin />;
    case ELEMENT_ID.EXECUTIVE_SUMMARY:
      return <ExecutiveSummary />;
    case ELEMENT_ID.REVIEWS_OPTIMIZATION:
      return <ReviewsOptimization />;
    case ELEMENT_ID.ORGANIC_SALES:
      return <OrganicSalesTable />;
    case ELEMENT_ID.FUNNEL:
      return <FunnelChart />;
    case ELEMENT_ID.SUMMARY_AUDIT:
      return <SummaryAudit />;
    case ELEMENT_ID.CAMPAIGN_METRICS:
      return (
        <div className={styles.campaign}>
          <h3>Campaign Metrics</h3>
          <CampaignsChart chartWidth={1800} />
        </div>
      );
    case ELEMENT_ID.ORGANIC_KEYWORD_PERFORMANCE:
      return <OrganicKeywordTable />;
    case ELEMENT_ID.ORGANIC_MISSING_TARGET:
      return <OrganicMissingTargetTable />;
    default:
      return elementId;
  }
};
